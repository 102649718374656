/* eslint-enable */
import {
  useEffect, useState, Suspense,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Router } from 'react-router-dom'
import Loader from 'components/Loader/Loader'
import TabList from 'components/TabList/TabList'
import TopBar from 'components/TopBar/TopBar'
import { RootState } from 'reducers/store'
import { auth } from '@osrdata/app_core'

import history from 'utils/history'

import AccessDenied from 'components/AccessDenied/AccessDenied'
import 'App.scss'

import navTabElements from 'utils/navTabElements'
import { terms } from 'common/terms'
import EventsComponent from 'components/Events'

const APP_NAME = 'PRCIté'
const ACCESS_PERM = 'prcite::ACCESS'

const App = () => {
  const dispatch = useDispatch()
  const [accessDenied, setAccessDenied] = useState(false)
  const { isLogged, appPermissions } = useSelector((state: RootState) => state.user)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!isLogged) {
      dispatch(auth.attemptLoginOnLaunch())
    } else if (appPermissions.length > 0) {
      if (!appPermissions.includes(ACCESS_PERM)) {
        setAccessDenied(true)
      }
      setIsLoading(false)
    }
  }, [isLogged, appPermissions, dispatch])

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Router history={history}>
          <TopBar
            appName={APP_NAME}
            innerComponent={<TabList tabs={navTabElements} />}
            hasAccess={!isLoading && !accessDenied}
          />
          {isLoading && <div id="app"><Loader message={terms.Common.loading} /></div>}
          {!isLoading && (accessDenied ? <AccessDenied /> : <EventsComponent />)}
        </Router>
      </Suspense>
    </>
  )
}

export default App

import {
  useRef, useState, useEffect,
} from 'react'
import { CheckedItems } from './Checkbox'

type LogLevel = 'ERROR' | 'INFO' | 'DEBUG' | 'SUCCESS' | 'FAIL'

type LogEntry = {
  logLevel: LogLevel;
  timeStep: string;
  humanTime: string;
  content: string;
}

export function messagesReducer(state: LogEntry[], message: LogEntry): LogEntry[] {
  return [...state, message]
}

export function messageSectionHandler(dispatch: any) {
  return (message: LogEntry) => {
    if (!message.content?.startsWith('CLOCK::')) {
      dispatch(message)
    }
  }
}

function MessageSection({ messages, checkedItems }: { messages: LogEntry[], checkedItems: CheckedItems }) {
  const messageContainerRef = useRef<HTMLDivElement | null>(null)
  const [prevScrollTop, setPrevScrollTop] = useState<number | null>(null)
  const [isScrollingUp, setIsScrollingUp] = useState(false)
  const [isSticked, setIsSticked] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')
  const formatMessage = (message: LogEntry) => {
    const logLevels = {
      SUCCESS: { color: 'green' },
      FAIL: { color: 'red' },
      ERROR: { color: 'red' },
      INFO: { color: 'black' },
      DEBUG: { color: 'gray' },
    }
    const textStyle = logLevels[message.logLevel]

    const formattedMessage = `${message.humanTime} — ${message.content}`
    const coloredMessage = <span style={textStyle}>{formattedMessage}</span>

    return coloredMessage
  }

  const searchFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value
    setSearchQuery(query)
  }

  const handleScroll = () => {
    if (messageContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageContainerRef.current!
      if (prevScrollTop !== null) {
        const scrollDelta = scrollTop - (prevScrollTop as number)
        if (scrollDelta < 0) {
          setIsScrollingUp(true)
        } else {
          if (Math.abs(scrollTop + clientHeight - scrollHeight) < 5) {
            setIsSticked(true)
          }
          setIsScrollingUp(false)
        }
      }
      setPrevScrollTop(scrollTop)
    }
  }

  const filteredMessages = messages
    .filter(
      message => {
        const {
          infoChecked, debugChecked, errorChecked, systemChecked,
        } = checkedItems
        const shouldDisplay = (infoChecked && ['INFO'].includes(message.logLevel))
          || (debugChecked && ['DEBUG'].includes(message.logLevel))
          || (errorChecked && ['ERROR'].includes(message.logLevel))
          || (systemChecked && ['FAIL', 'SUCCESS'].includes(message.logLevel))
        return shouldDisplay
      },
    )
    .filter(message => message.content.toLowerCase().includes(searchQuery.toLowerCase()))
    .map(message => formatMessage(message))

  useEffect(() => {
    if (isScrollingUp && isSticked) {
      setIsSticked(false)
    }
    const messageContainer = messageContainerRef.current
    const { scrollTop, scrollHeight, clientHeight } = messageContainer!
    if (Math.abs(scrollTop + clientHeight - scrollHeight) < 5) {
      setIsSticked(true)
    }
    if (messageContainer && isSticked && !isScrollingUp) {
      messageContainer.scrollTop = messageContainer.scrollHeight
    }
  }, [messages])

  const createUniqueIdGenerator = () => {
    let counter = 0
    const generateUniqueId = () => {
      counter += 1
      return counter
    }
    return generateUniqueId
  }
  const generateUniqueId = createUniqueIdGenerator()

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '85%',
        marginLeft: '1%',
        marginRight: '10%',
      }}
    >
      <input
        type="text"
        placeholder="Recherche de Messages"
        value={searchQuery}
        onChange={searchFilter}
      />
      <div
        ref={messageContainerRef}
        style={{ overflowY: 'auto', maxHeight: '100%' }}
        onScroll={handleScroll}
      >
        {filteredMessages.map((message: JSX.Element) => (
          <div key={generateUniqueId()}>{message}</div>
        ))}
      </div>
    </div>
  )
}

export default MessageSection

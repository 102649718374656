type LogLevel = 'ERROR' | 'INFO' | 'DEBUG' | 'SUCCESS' | 'FAIL'

type LogEntry = {
  logLevel: LogLevel;
  timeStep: string;
  humanTime: string;
  content: string;
}

type MessageHandler = (message: LogEntry) => void

export default class WebSocketService {
  private socket: WebSocket

  private messageHandlers: MessageHandler[] = []

  constructor(url: string, dispatchMessage: any) {
    this.socket = new WebSocket(url)
    this.socket.onopen = () => {
      const connectLogEntry: LogEntry = {
        logLevel: 'SUCCESS',
        timeStep: '',
        humanTime: '',
        content: 'Connection établie',
      }
      dispatchMessage(connectLogEntry)
      console.log('WebSocket connection established')
    }

    this.socket.onclose = () => {
      const disconnectLogEntry: LogEntry = {
        logLevel: 'FAIL',
        timeStep: '',
        humanTime: '',
        content: 'Connection perdue',
      }
      dispatchMessage(disconnectLogEntry)
      console.log('WebSocket connection closed')
    }

    this.socket.onmessage = (event: MessageEvent) => {
      const message: LogEntry = JSON.parse(event.data)
      this.messageHandlers.forEach((handler: MessageHandler) => {
        handler(message)
      })
    }
  }

  addMessageHandler(handler: MessageHandler) {
    this.messageHandlers.push(handler)
  }

  removeMessageHandler(handler: MessageHandler) {
    const index = this.messageHandlers.indexOf(handler)
    if (index !== -1) {
      this.messageHandlers.splice(index, 1)
    }
  }

  send(message: string) {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message)
    }
  }

  close() {
    this.socket.close()
  }
}

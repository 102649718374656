type LogLevel = 'ERROR' | 'INFO' | 'DEBUG' | 'SUCCESS' | 'FAIL'

type LogEntry = {
  logLevel: LogLevel;
  timeStep: string;
  humanTime: string;
  content: string;
}

export function clockReducer(_: string, payload: string): string {
  return payload
}

export function clockHandler(dispatch: any) {
  return (message: LogEntry) => {
    if (message.content?.startsWith('CLOCK::')) {
      const content = message.content.replace('CLOCK::', '')
      dispatch(content)
    }
  }
}

function Clock({ clockTime }: { clockTime: string }) {
  return <div style={{ marginLeft: '90%', fontSize: 32 }}><b>{clockTime}</b></div>
}

export default Clock

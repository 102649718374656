import {
  useEffect, useState, useRef, useReducer,
} from 'react'

import { useSelector } from 'react-redux'
import { RootState } from 'reducers/store'
import { GATEWAY_DOMAIN } from 'config/config'
import MessageSection, { messagesReducer, messageSectionHandler } from './MessageSection'
import Clock, { clockReducer, clockHandler } from './Clock'
import Checkbox from './Checkbox'
import Input from './Input'
import WebSocketService from './WebSocketService'

const EventsComponent = () => {
  const { isLogged, appPermissions } = useSelector((state: RootState) => state.user)
  const [messages, dispatchMessage] = useReducer(messagesReducer, [])
  const [clockTime, dispatchClockTime] = useReducer(clockReducer, '')

  const [checkedItems, setCheckedItems] = useState({
    infoChecked: true,
    debugChecked: false,
    errorChecked: false,
    systemChecked: false,
  })

  const TOKEN = localStorage.getItem('access_token')
  const urlParams = new URLSearchParams(window.location.search)
  const SPEED = urlParams.get('speed') || 1
  const LOG_LEVEL = urlParams.get('log_level') || 'DEBUG'
  const ROOM_NAME = urlParams.get('room_name') || '0'

  let WS_URL = ''
  if (process.env.REACT_APP_LOCALHOST_DEVELOPMENT === 'true') {
    WS_URL = 'ws://localhost:8000/prcite/demo/'
      + `?speed=${SPEED}&log_level=${LOG_LEVEL}&room_name=${ROOM_NAME}`
  } else if (TOKEN) {
    WS_URL = `wss://${GATEWAY_DOMAIN.url}/prcite/demo/`
      + `?_auth_token=${TOKEN}&speed=${SPEED}&log_level=${LOG_LEVEL}&room_name=${ROOM_NAME}`
  }

  const useWebSocket = (url: string) => {
    const ws = useRef<WebSocketService>()
    useEffect(() => {
      if (!ws.current && isLogged && appPermissions && url) {
        ws.current = new WebSocketService(url, dispatchMessage)
        ws.current.addMessageHandler(messageSectionHandler(dispatchMessage))
        ws.current.addMessageHandler(clockHandler(dispatchClockTime))
      }
    }, [isLogged, appPermissions, url])
    return ws
  }

  const ws = useWebSocket(WS_URL)

  return (
    <div id="app">
      <Checkbox checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
      <Clock clockTime={clockTime} />
      <MessageSection messages={messages} checkedItems={checkedItems} />
      <Input webSocketService={ws} />
    </div>
  )
}

export default EventsComponent

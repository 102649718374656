export type CheckedItems = {
  infoChecked: boolean;
  debugChecked: boolean;
  errorChecked: boolean;
  systemChecked: boolean;
}

type LogType = 'error' | 'info' | 'debug' | 'system'

/* eslint-disable */
type Checkbox = {
  type: LogType;
  label: string;
}

function CheckBox({checkedItems, setCheckedItems}: {checkedItems: CheckedItems, setCheckedItems: React.Dispatch<React.SetStateAction<CheckedItems>>}) {
  const checkboxes: Checkbox[] = [
    { type: 'info', label: 'INFO' },
    { type: 'debug', label: 'DEBUG' },
    { type: 'error', label: 'ERROR' },
    { type: 'system', label: 'SYSTEM' },
  ]

  const handleCheckboxChange = (type: keyof CheckedItems) => {
    setCheckedItems((prevState: CheckedItems) => ({
      ...prevState,
      [type]: !prevState[type],
    }))
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <h1 style={{ marginRight: '1%', marginLeft: '1%' }}>Events</h1>
      {checkboxes.map(({ type, label }: Checkbox) => (
        <label key={type} style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }} htmlFor={`${type}Checkbox`}>
          <input
            type="checkbox"
            id={`${type}Checkbox`}
            checked={checkedItems[`${type}Checked`]}
            onChange={() => handleCheckboxChange(`${type}Checked`)}
            style={{ marginRight: '5px' }}
          />
          <span style={{ fontWeight: checkedItems[`${type}Checked`] ? 'bold' : 'normal' }}>{label}</span>
        </label>
      ))}
    </div>
  )
}

export default CheckBox

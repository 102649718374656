import {
  useRef, useEffect, useState, MutableRefObject,
} from 'react'
import WebSocketService from './WebSocketService'

function MessageInput({ webSocketService }: { webSocketService: MutableRefObject<WebSocketService | undefined> }) {
  const [newMessage, setNewMessage] = useState<string>('')
  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const sendMessage = (message: string) => {
    if (webSocketService.current) {
      console.log('Sent message:', message)
      webSocketService.current.send(message)
    } else {
      console.log(webSocketService)
    }
  }

  const handleSendClick = () => {
    sendMessage(newMessage)
    setNewMessage('')
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSendClick()
    }
  }

  return (
    <div style={{
      display: 'flex', flexDirection: 'row', width: '100%', marginRight: '1%', marginLeft: '1%',
    }}
    >
      <input
        type="text"
        ref={inputRef}
        value={newMessage}
        onChange={e => setNewMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Saisie des commandes"
        style={{ flexGrow: 1 }}
      />
      <button
        type="button"
        onClick={handleSendClick}
        style={{ width: '100px', marginRight: '2%' }}
      >
        Send
      </button>
    </div>
  )
}

export default MessageInput
